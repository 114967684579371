import { Chip, ChipProps, Tooltip } from '@mui/material';
import { EVoiStatusEnum } from '../../api/eVoiTransaction';
import useUser from '../../hooks/useUser';

interface VoiStatusChipProps extends ChipProps {
    status?: EVoiStatusEnum;
    requested?: boolean;
}

function VoiStatusChip({ status, requested, ...chipProps }: VoiStatusChipProps) {
    const user = useUser();
    const isWorkspaceOwner = user?.de_rep_organisation !== null;

    switch (status) {
        case 'pending':
        case 'in_progress':
        case 'submitted':
            return (
                <Tooltip title="Electronic Verification of Identity has been requested, and is awaiting completion by the ID provider.">
                    <Chip label="PENDING" color="secondary" {...chipProps} />
                </Tooltip>
            );
        case 'completed':
            return <Chip label="COMPLETED" color="success" {...chipProps} />;
        case 'expired':
            return <Chip label="EXPIRED" color="error" {...chipProps} />;
        case 'cancelled':
            return <Chip label="CANCELLED" color="default" {...chipProps} />;
        default:
            if (requested) {
                return (
                    <Chip
                        label="REQUESTED"
                        color={isWorkspaceOwner ? 'warning' : 'secondary'}
                        {...chipProps}
                    />
                );
            }
            return <Chip label="NOT REQUESTED" color="default" {...chipProps} />;
    }
}
export default VoiStatusChip;
