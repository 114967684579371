import TableRow from '@mui/material/TableRow';
import { createElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { WorkspaceMeta } from '../../api/workspace';
import { Column } from './WorkspacesTable';

interface WorkspaceTableRowProps {
    columns: Column[];
    workspace: WorkspaceMeta;
}

function WorkspaceTableRow({ columns, workspace }: WorkspaceTableRowProps) {
    const navigate = useNavigate();
    return (
        <TableRow
            hover
            onClick={() => navigate(`/workspace/${workspace.id}`)}
            sx={{
                textDecoration: 'none',
                cursor: 'pointer',
                '&:last-child td': {
                    borderBottom: 0,
                },
            }}
        >
            {columns.map((column) =>
                createElement(column.Component, {
                    workspace,
                    column,
                    key: column.id,
                })
            )}
        </TableRow>
    );
}

export default WorkspaceTableRow;
