import { Box, Stack, TableCell, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { deceasedDetailQuery } from '../../api/deceasedDetail';
import { DocumentUnion, PoaTypeEnum } from '../../api/document';
import { useWorkspace } from '../../pages/workspace/workspaceContext';
import DateTimeString from '../DateTimeString';
import DocumentInfectedChip from '../DocumentInfectedChip';
import DocumentVerifiedChip from '../DocumentVerifiedChip';
import { Column } from './DocumentsTable';

export interface CellComponentProps {
    document: DocumentUnion;
    column: Column;
}

function DocumentTableRowFile({ document }: CellComponentProps) {
    return (
        <TableCell
            sx={{
                maxWidth: '25ch',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                color: 'inherit',
            }}
        >
            <Box
                sx={{
                    textDecoration: 'none',
                    color: 'inherit',
                    '&:hover': { textDecoration: !document.is_infected ? 'underline' : undefined },
                }}
            >
                {document.file_name}
            </Box>
        </TableCell>
    );
}

function DocumentTableRowCreatedAt({ document }: CellComponentProps) {
    return <TableCell>{DateTimeString(document.created_at)}</TableCell>;
}

function DocumentTableRowUploadedByUser({ document }: CellComponentProps) {
    return <TableCell>{document.uploaded_by.name}</TableCell>;
}

function DocumentTableRowUploadedByOrganisation({ document }: CellComponentProps) {
    return (
        <TableCell>
            {document.uploaded_by.organisation_external_display_name ||
                document.uploaded_by.organisation_name}
        </TableCell>
    );
}

function DocumentTableRowUploadedByIndustry({ document }: CellComponentProps) {
    return <TableCell>{document.uploaded_by.organisation_industry}</TableCell>;
}

function DocumentTableRowType({ document }: CellComponentProps) {
    if (!('document_type' in document)) return <TableCell />;
    return <TableCell>{document.document_type}</TableCell>;
}

function DocumentTableRowJurisdiction({ document }: CellComponentProps) {
    if (!('jurisdiction' in document)) return <TableCell />;
    return <TableCell>{document.jurisdiction}</TableCell>;
}

function DocumentTableRowInfectedStatus({ document }: CellComponentProps) {
    return <TableCell>{document.is_infected ? 'Infected' : undefined}</TableCell>;
}

export const poaTypes: Record<PoaTypeEnum, string> = {
    grant_of_probate: 'Grant of Probate',
    grant_of_representation: 'Grant of representation',
    letters_of_administration: 'Letters of Administration',
    letters_of_administration_with_will: 'Letters of Administration with Will',
    will: 'Will',
    other: 'Other',
};

function DocumentTablePoaType({ document }: CellComponentProps) {
    if (!('poa_type' in document)) return <TableCell />;
    return <TableCell>{poaTypes[document.poa_type]}</TableCell>;
}

function DocumentTableRetrievedFromRedcrest({ document }: CellComponentProps) {
    if (!('source' in document)) return <TableCell />;
    return (
        <TableCell>
            {document.source === 'vic_supreme_court' ? (
                <DocumentVerifiedChip
                    verified={true}
                    label={'Retrieved from RedCrest'}
                    tooltip={
                        <Stack gap={0.5} sx={{ p: 1, pb: 1.5 }}>
                            <Typography>Verified data fields</Typography>
                            <Typography variant="body2">
                                Application number: {document.application_number}
                            </Typography>
                            <Typography variant="body2">
                                Unique identifier: {document.unique_identifier}
                            </Typography>
                        </Stack>
                    }
                />
            ) : null}
        </TableCell>
    );
}

function DocumentTableRowPoAUploadedBy({ document }: CellComponentProps) {
    if (!('source' in document)) return <TableCell />;
    return (
        <TableCell>
            {document.source === 'vic_supreme_court' ? 'estateXchange' : document.uploaded_by.name}
        </TableCell>
    );
}

function DocumentTableRowStatus({ document }: CellComponentProps) {
    const workspace = useWorkspace();
    const { data: details } = useQuery(deceasedDetailQuery(workspace.deceased_detail.id));

    if (document.is_infected) {
        return (
            <TableCell>
                <DocumentInfectedChip />
            </TableCell>
        );
    }

    if ('verified' in document && document.verified) {
        return (
            <TableCell>
                <DocumentVerifiedChip
                    verified={document.verified as boolean}
                    label={'Verified by ADC'}
                    tooltip={
                        <Stack gap={0.5} sx={{ p: 1, pb: 1.5 }}>
                            <Typography variant="subtitle2" sx={{ ml: -0.3 }}>
                                Australian Death Check
                            </Typography>
                            <Typography>Verified data fields:</Typography>
                            <Typography variant="body2">
                                Given names: {details?.given_names}
                            </Typography>
                            <Typography variant="body2">
                                Family name: {details?.family_name}
                            </Typography>
                            <Typography variant="body2">
                                Registration no.: {document.registration_number}
                            </Typography>
                            <Typography variant="body2">
                                State of death: {document.registration_state}
                            </Typography>
                            <Typography variant="body2">
                                Year of registration: {document.registration_year}
                            </Typography>
                        </Stack>
                    }
                />
            </TableCell>
        );
    }

    return <TableCell />;
}

export {
    DocumentTablePoaType,
    DocumentTableRetrievedFromRedcrest,
    DocumentTableRowCreatedAt,
    DocumentTableRowFile,
    DocumentTableRowInfectedStatus,
    DocumentTableRowJurisdiction,
    DocumentTableRowPoAUploadedBy,
    DocumentTableRowStatus,
    DocumentTableRowType,
    DocumentTableRowUploadedByIndustry,
    DocumentTableRowUploadedByOrganisation,
    DocumentTableRowUploadedByUser,
};
