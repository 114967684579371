import {
    Button,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    Stack,
    Typography,
} from '@mui/material';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useModal } from 'mui-modal-provider';
import { enqueueSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { FormContainer, SelectElement, TextFieldElement } from 'react-hook-form-mui';
import { useNavigate } from 'react-router-dom';
import {
    updateWorkspaceMembershipMutation,
    workspaceMembershipQuery,
} from '../api/workspaceMembership';
import useUser from '../hooks/useUser';
import { useWorkspace } from '../pages/workspace/workspaceContext';

function AcceptInvitationHeaderWrapper() {
    const user = useUser();
    if (user?.user_type !== 'institution_employee') return null;

    return <AcceptInvitationHeader />;
}

function AcceptInvitationHeader() {
    const { showModal } = useModal();
    const navigate = useNavigate();
    const workspace = useWorkspace();
    const membershipId = workspace.memberships[0];

    const membershipQuery = useQuery(workspaceMembershipQuery(membershipId));
    const updateWorkspaceMembership = useMutation(updateWorkspaceMembershipMutation());

    const handleAccept = () => {
        updateWorkspaceMembership
            .mutateAsync({ id: membershipId, status: 'in_progress' })
            .then(() => {
                enqueueSnackbar('Invitation accepted.', { variant: 'success' });
            });
    };

    const handleDecline = (reason: string) => {
        updateWorkspaceMembership
            .mutateAsync({ id: membershipId, status: 'declined', decline_reason: reason })
            .then(() => {
                enqueueSnackbar('Invitation declined.', { variant: 'success' });
                navigate('/');
            });
    };

    return (
        <Collapse in={membershipQuery.data?.status === 'invited'} appear sx={{ flexShrink: 0 }}>
            <Stack
                direction="row"
                gap={1}
                alignItems="center"
                justifyContent="center"
                p={1.5}
                mb={2}
                bgcolor="primary.main"
                borderRadius={5}
            >
                <Typography fontWeight="medium">Accept Invitation to Workspace?</Typography>

                <Button
                    startIcon={<IconCheck />}
                    variant="outlined"
                    color="inherit"
                    sx={{ ml: 2 }}
                    onClick={handleAccept}
                >
                    Accept
                </Button>

                <Button
                    startIcon={<IconX />}
                    variant="outlined"
                    color="inherit"
                    onClick={() =>
                        showModal(DeclineInvitationDialog, {
                            onDecline: handleDecline,
                        })
                    }
                >
                    Decline
                </Button>
            </Stack>
        </Collapse>
    );
}

interface DeclineInvitationDialogProps extends DialogProps {
    onClose?: () => void;
    onDecline: (reason: string) => void;
}

function DeclineInvitationDialog({
    onClose,
    onDecline,
    ...dialogProps
}: DeclineInvitationDialogProps) {
    const formMethods = useForm({
        mode: 'onChange',
        defaultValues: {
            reason: '',
            otherReason: '',
        },
    });

    const watchReason = formMethods.watch('reason');

    const handleSubmit = () => {
        const fields = formMethods.getValues();
        onDecline(fields.reason === 'other' ? fields.otherReason : fields.reason);
        onClose?.();
    };

    return (
        <Dialog onClose={onClose} scroll="body" {...dialogProps}>
            <DialogTitle>Decline Invitation</DialogTitle>
            <FormContainer formContext={formMethods} onSuccess={handleSubmit}>
                <DialogContent>
                    <Stack gap={4} sx={{ mt: -2 }}>
                        <Typography>
                            Are you sure you want to decline the Invitation to this Workspace? This
                            action cannot be undone.
                        </Typography>
                        <SelectElement
                            name="reason"
                            label="Reason for declining"
                            options={[
                                {
                                    id: 1,
                                    label: 'Not a customer/client/member',
                                    value: 'Not a customer/client/member',
                                },
                                {
                                    id: 2,
                                    label: 'Case completed outside eXc',
                                    value: 'Case completed outside eXc',
                                },
                                { id: 3, label: 'Other', value: 'other' },
                            ]}
                            valueKey="value"
                            fullWidth
                            required
                        />
                        {watchReason === 'other' && (
                            <TextFieldElement
                                multiline
                                required
                                name="otherReason"
                                label="Other reason"
                                fullWidth
                            />
                        )}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="inherit">
                        Cancel
                    </Button>
                    <Button type="submit" variant="contained" color="error">
                        Decline
                    </Button>
                </DialogActions>
            </FormContainer>
        </Dialog>
    );
}

export default AcceptInvitationHeaderWrapper;
