// Copyright (C) 2024, Affinda

import { Box, BoxProps, useTheme } from '@mui/material';
import { usePrevious, useUpdateEffect } from '@react-hookz/web';
import { useRef } from 'react';

export interface CountBadgeProps extends BoxProps {
    count: number;
    size?: 'medium' | 'small';
    updateColor?: 'success' | 'error' | 'warning' | 'primary';
}

export default function CountBadge(props: CountBadgeProps) {
    const { count, size = 'medium', updateColor, ...boxProps } = props;

    const theme = useTheme();
    const elRef = useRef<HTMLElement | null>(null);
    const previous = usePrevious(count) || 0;

    useUpdateEffect(() => {
        if (updateColor && count > previous) {
            elRef.current?.animate(
                [
                    {
                        background: theme.palette[updateColor].main,
                        color: theme.palette[updateColor].contrastText,
                        offset: 0,
                    },
                ],
                2000
            );
        }
    }, [count]);

    if (!count) return null;

    return (
        <Box
            {...boxProps}
            ref={elRef}
            sx={{
                bgcolor: 'action.selected',
                color: 'text.secondary',
                fontSize: '0.8125rem',
                fontWeight: 'medium',
                lineHeight: 1,
                mr: -0.5,
                borderRadius: '99px',
                ...(size === 'medium' ? { px: 1, py: 0.5 } : { px: 0.75, py: 0.25 }),
                '.Mui-selected &': {
                    bgcolor: 'primary.main',
                    color: 'text.primary',
                },
                ...boxProps.sx,
            }}
        >
            {count}
        </Box>
    );
}
