import { Chip, Tooltip } from '@mui/material';
import { IconAlertTriangle } from '@tabler/icons-react';

function DocumentInfectedChip() {
    return (
        <Tooltip title="A problem has been encountered with this document. Click to view details">
            <Chip
                sx={{
                    pl: 0.25,
                }}
                icon={<IconAlertTriangle size={20} />}
                label="Document error"
                color="warning"
                onClick={() => {}}
            />
        </Tooltip>
    );
}

export default DocumentInfectedChip;
