// Copyright (C) 2024, Affinda

import { Box, Stack, TableCell } from '@mui/material';
import { PropsWithChildren } from 'react';

interface TableRowControlsProps extends PropsWithChildren {
    forceDisplay?: boolean;
}

function TableRowControls({ forceDisplay, children }: TableRowControlsProps) {
    return (
        <TableCell
            padding="none"
            sx={{
                position: 'sticky',
                right: 0,
                pr: '0 !important',
                mr: 0,
            }}
        >
            <Box
                sx={{
                    'tr:not(:hover) &': {
                        visibility: forceDisplay === true ? undefined : 'hidden',
                    },
                }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    sx={{
                        backgroundColor: 'background-paper',
                        pr: 1,
                    }}
                >
                    {children}
                </Stack>
            </Box>
        </TableCell>
    );
}

export default TableRowControls;
