import {
    QueryFunctionContext,
    UseInfiniteQueryOptions,
    UseMutationOptions,
    UseQueryOptions,
} from '@tanstack/react-query';
import { queryClient } from './queryClient';
import { components, operations } from './schema';
import { api, perPage } from './utils';

export type WorkspaceMeta = components['schemas']['WorkspaceMeta'];
export type WorkspacesRequest = operations['workspaces_list']['parameters']['query'];
export type WorkspacesResponse = components['schemas']['PaginatedWorkspaceMetaList'];
export type WorkspaceDetail = components['schemas']['WorkspaceDetail'];

// gets all workspaces for the logged in user
export function workspacesQuery(options?: WorkspacesRequest) {
    return {
        queryKey: ['workspaces', 'list', options],
        queryFn: ({ signal }: QueryFunctionContext) =>
            api<WorkspacesResponse>({
                url: `/workspaces`,
                params: options,
                paramsSerializer: () => {
                    try {
                        var p = new URLSearchParams();

                        // Ensure the array gets serialized to multiple query params
                        options?.membership_status?.forEach((status) =>
                            p.append('membership_status', status)
                        );

                        options?.status?.forEach((status) => p.append('status', status));

                        if (options?.ordering) {
                            p.append('ordering', options.ordering);
                        }

                        if (options?.search) {
                            p.append('search', options.search);
                        }

                        return p.toString();
                    } catch (error) {
                        throw new Error(`Failed to serialize params: ${error}`);
                    }
                },

                method: 'GET',
                signal,
            }).then((response) => response.data),
        getNextPageParam: (lastPage, pages) => (lastPage.next ? pages.length * perPage : undefined),
        initialPageParam: 1,
    } satisfies UseInfiniteQueryOptions<WorkspacesResponse>;
}

// gets a single workspace by id, note that it returns more details than the list endpoint
export function workspaceQuery(id: string) {
    return {
        queryKey: ['workspace', id],
        queryFn: ({ signal }: QueryFunctionContext) =>
            api<WorkspaceDetail>({
                url: `/workspaces/${id}`,
                method: 'GET',
                signal,
            }).then((response) => response.data),
    } satisfies UseQueryOptions;
}

export type WorkspaceCreate = components['schemas']['WorkspaceCreate'];
export type WorkspacesCreate400 = components['schemas']['WorkspacesCreateErrorResponse400'];

export type WorkspaceCreateRequest = components['schemas']['WorkspaceCreateRequest'];
export function createWorkspaceMutation() {
    return {
        mutationFn: (data: WorkspaceCreateRequest) =>
            api<WorkspaceCreate>({
                url: `/workspaces`,
                method: 'POST',
                data,
            }).then((response) => response.data),
    };
}

export type WorkspaceUpdate = components['schemas']['WorkspaceUpdate'];

export function updateWorkspaceMutation(workspaceId: string) {
    return {
        mutationFn: (data: WorkspaceUpdate) =>
            api<WorkspaceUpdate>({
                url: `/workspaces/${workspaceId}`,
                method: 'PATCH',
                data,
            }).then((response) => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries(workspaceQuery(workspaceId));
            queryClient.invalidateQueries(workspacesQuery());
        },
    };
}

export type WorkspaceClose = operations['workspaces_close_create']['parameters']['path'];

export function closeWorkspaceMutation(workspaceId: string) {
    return {
        mutationFn: () =>
            api<WorkspaceMeta>({
                url: `/workspaces/${workspaceId}/close`,
                method: 'POST',
                data: {},
            }).then((response) => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['workspaces', 'list'] });
        },
    } satisfies UseMutationOptions<any, any, any, any>;
}
