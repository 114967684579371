import { Box, BoxProps, Tooltip } from '@mui/material';
import { IconInfoCircle } from '@tabler/icons-react';
import { ReactNode } from 'react';

interface InfoTooltipProps extends Omit<BoxProps, 'title'> {
    title: ReactNode;
    size?: number;
}

function InfoTooltip({ title, size, ...boxProps }: InfoTooltipProps) {
    return (
        <Tooltip title={title}>
            <Box
                flexShrink={0}
                color="text.secondary"
                component={IconInfoCircle}
                size={size || 20}
                {...boxProps}
                alignSelf="-moz-initial"
                sx={{ strokeWidth: '1.7px', ...boxProps.sx }}
            />
        </Tooltip>
    );
}

export default InfoTooltip;
