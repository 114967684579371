import { Button, Stack, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useModal } from 'mui-modal-provider';
import { enqueueSnackbar } from 'notistack';
import { FC } from 'react';
import { queryClient } from '../../api/queryClient';
import {
    RelatedPersonMeta,
    deleteVoIDocumentMutation,
    relatedPeopleQuery,
    relatedPersonQuery,
    uploadVoIDocumentMutation,
} from '../../api/relatedPerson';
import { useStore } from '../../store/storeContext';
import QueryProgress from '../QueryProgress';
import UploadGenericDocumentDialog from '../UploadGenericDocumentDialog';
import DocumentsTable from '../documentsTable/DocumentsTable';
import { TabProps } from './RelatedPersonDrawer';

interface RelatedPersonVoIProps extends Omit<TabProps, 'person'> {
    person: RelatedPersonMeta;
}

const RelatedPersonIdentityDocuments: FC<RelatedPersonVoIProps> = ({ person, readOnly }) => {
    const store = useStore();
    const { showModal } = useModal();

    const query = useQuery(relatedPersonQuery(person.id));
    const personDetail = query.data;

    const uploadDocumentMutation = useMutation({
        ...uploadVoIDocumentMutation(),
        onSuccess: () => {
            enqueueSnackbar('Document uploaded', { variant: 'success' });
            queryClient.invalidateQueries(relatedPersonQuery(person.id));
            queryClient.invalidateQueries(relatedPeopleQuery(person.workspace));
        },
    });

    const handleUpload = async (file: File) => {
        if (!personDetail) return;

        await uploadDocumentMutation.mutateAsync({
            id: personDetail.id,
            file,
        });
    };

    const deleteDocumentMutation = useMutation(deleteVoIDocumentMutation());

    const handleDeleteDocument = (id: string) => {
        deleteDocumentMutation.mutateAsync(id).then(() => {
            queryClient.invalidateQueries(relatedPersonQuery(person.id));
            queryClient.invalidateQueries(relatedPeopleQuery(person.workspace));
        });
    };

    return (
        <Stack gap={4} flexGrow={1}>
            {!readOnly ? (
                <Button
                    variant="outlined"
                    color="inherit"
                    sx={{ width: 'fit-content' }}
                    onClick={() =>
                        showModal(UploadGenericDocumentDialog, {
                            title: 'Upload Identity Documents',
                            taskType: 'proof_of_identity',
                            forceRequireConsent: true,
                            onUpload: handleUpload,
                        })
                    }
                >
                    Upload identity documents
                </Button>
            ) : null}
            <QueryProgress query={query}>
                {!!personDetail?.scanned_id_documents?.length ? (
                    <DocumentsTable
                        documents={[...personDetail?.scanned_id_documents]}
                        onDelete={handleDeleteDocument}
                    />
                ) : readOnly ? (
                    <Typography>Not provided</Typography>
                ) : null}
            </QueryProgress>
        </Stack>
    );
};

export default RelatedPersonIdentityDocuments;

// import { Alert, Button, Stack, Typography } from '@mui/material';
// import { useMutation, useQuery } from '@tanstack/react-query';
// import { FC } from 'react';
// import { FormContainer, useForm } from 'react-hook-form-mui';
// import { queryClient } from '../../api/queryClient';
// import {
//     RelatedPersonMeta,
//     deleteVoIDocumentMutation,
//     relatedPeopleQuery,
//     relatedPersonQuery,
//     uploadVoIDocument,
// } from '../../api/relatedPerson';
// import { useStore } from '../../store/storeContext';
// import QueryProgress from '../QueryProgress';
// import DocumentsTable from '../documentsTable/DocumentsTable';
// import DragAndDropFileField from '../fields/DragAndDropFileField';
// import { TabProps } from './RelatedPersonDrawer';

// interface RelatedPersonVoIProps extends Omit<TabProps, 'person'> {
//     person: RelatedPersonMeta;
// }

// const RelatedPersonIdentityDocuments: FC<RelatedPersonVoIProps> = ({ person, readOnly }) => {
//     const store = useStore();

//     const query = useQuery(relatedPersonQuery(person.id));
//     const personDetail = query.data;

//     const handleUpload = (file: File) => {
//         if (!personDetail) return;
//         store.uploadDocument(
//             file,
//             (file: File) => uploadVoIDocument(personDetail.id, file),
//             () => {
//                 queryClient.invalidateQueries(relatedPersonQuery(person.id));
//                 queryClient.invalidateQueries(relatedPeopleQuery(person.workspace));
//             }
//         );
//     };

//     const deleteDocumentMutation = useMutation(deleteVoIDocumentMutation());

//     const handleDeleteDocument = (id: string) => {
//         deleteDocumentMutation.mutateAsync(id).then(() => {
//             queryClient.invalidateQueries(relatedPersonQuery(person.id));
//             queryClient.invalidateQueries(relatedPeopleQuery(person.workspace));
//         });
//     };

//     const formMethods = useForm({
//         defaultValues: {
//             file: null,
//         },
//     });

//     const handleSubmit = () => {
//         const values = formMethods.getValues();
//         if (!personDetail) return;
//         if (!values.file) return;

//         store.uploadDocument(
//             values.file,
//             (file: File) => uploadVoIDocument(personDetail.id, file),
//             () => {
//                 queryClient.invalidateQueries(relatedPersonQuery(person.id));
//                 queryClient.invalidateQueries(relatedPeopleQuery(person.workspace));
//             }
//         );
//         formMethods.reset();
//     };

//     const watchFile = formMethods.watch('file');

//     return (
//         <Stack gap={4} flexGrow={1}>
//             <QueryProgress query={query}>
//                 <Stack gap={2}>
//                     {!readOnly ? (
//                         <>
//                             <Alert severity="info">
//                                 Please ensure you hold the right consents to share any documents
//                                 containing personal and/or sensitive information.
//                             </Alert>
//                             <FormContainer formContext={formMethods} onSuccess={handleSubmit}>
//                                 <DragAndDropFileField name="file" required />
//                                 {watchFile ? (
//                                     <Stack
//                                         direction="row"
//                                         justifyContent="flex-end"
//                                         gap={2}
//                                         sx={{ pt: 2 }}
//                                     >
//                                         <Button color="inherit">Cancel</Button>

//                                         <Button type="submit" variant="contained">
//                                             Save
//                                         </Button>
//                                     </Stack>
//                                 ) : null}
//                             </FormContainer>
//                         </>
//                     ) : null}

//                     {!!personDetail?.scanned_id_documents?.length ? (
//                         <DocumentsTable
//                             documents={[...personDetail?.scanned_id_documents]}
//                             onDelete={handleDeleteDocument}
//                         />
//                     ) : readOnly ? (
//                         <Typography>Not provided</Typography>
//                     ) : null}
//                 </Stack>
//             </QueryProgress>
//         </Stack>
//     );
// };

// export default RelatedPersonIdentityDocuments;
