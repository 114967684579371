import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Stack,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormContainer, SelectElement, TextFieldElement } from 'react-hook-form-mui';
import { z } from 'zod';
import {
    createRelatedPersonMutation,
    relatedPersonRoleLabels,
    updateRelatedPersonMutation,
} from '../../api/relatedPerson';
import {
    characterLimit,
    noSpecialChars,
    optionalString,
    requiredString,
} from '../../validationRules';
import ReadonlyField from '../fields/ReadonlyField';
import { TabProps } from './RelatedPersonDrawer';

const validationSchema = z
    .object({
        family_name: optionalString.and(characterLimit(40)).and(noSpecialChars),
        given_names: optionalString.and(characterLimit(70)).and(noSpecialChars),
        is_professional_trustee: z.boolean(),
        is_lpr: z.enum(['yes', 'no']).optional(),
        role: requiredString,
        role_detail: optionalString.and(characterLimit(20)).and(noSpecialChars),
    })
    .refine(
        (data) => {
            // If not a professional trustee, then family name and given names are required
            if (!data.is_professional_trustee) {
                return !!data.family_name && !!data.given_names;
            }
            return true;
        },
        {
            message: 'Family name and given names are required.',
            path: ['family_name', 'given_names'],
        }
    );

export const RelatedPersonDetails: FC<TabProps> = ({
    workspace,
    person,
    onCreated,
    onClose,
    assumeLpr,
    readOnly,
}) => {
    const [relatedPersonIsOwnerOrg, setRelatedPersonIsOwnerOrg] = useState<boolean>(
        person?.is_professional_trustee || false
    );
    const [isLpr, setIsLpr] = useState<boolean | undefined>(
        assumeLpr || person?.is_professional_trustee
    );
    const form = useForm({
        mode: 'onChange',
        defaultValues: {
            family_name: person?.family_name || '',
            given_names: person?.given_names || '',
            full_name: person?.full_name || '',
            is_professional_trustee: person?.is_professional_trustee || false,
            is_lpr: person ? (person?.is_lpr ? 'yes' : 'no') : assumeLpr ? 'yes' : undefined,
            role: person?.role,
            role_detail: person?.role_detail || '',
        },
        resolver: zodResolver(validationSchema),
    });

    const { setValue, watch, getValues } = form;

    const watchRole = watch('role');

    const roleOptions: (keyof typeof relatedPersonRoleLabels)[] = relatedPersonIsOwnerOrg
        ? ['executor', 'administrator', 'proposed_administrator']
        : isLpr
          ? ['executor', 'administrator', 'proposed_administrator', 'next_of_kin']
          : [
                'trustee',
                'estate_beneficiary',
                'superannuation_beneficiary',
                'life_insurance_beneficiary',
                'claimant',
                'other',
            ];

    useEffect(() => {
        if (watchRole && !roleOptions.includes(watchRole)) {
            setValue('role', undefined);
        }
    }, [watchRole, roleOptions]);

    const createMutation = useMutation(createRelatedPersonMutation());
    const updateMutation = useMutation(person ? updateRelatedPersonMutation(person.id) : {});
    const isLoading = createMutation.isPending || updateMutation.isPending;

    const handleSubmit = () => {
        const values = getValues();
        const updateData = {
            role: values.role,
            role_detail: values.role_detail,
        };

        if (!person) {
            createMutation
                .mutateAsync({
                    workspace: workspace.id,
                    family_name: values.family_name,
                    given_names: values.given_names,
                    role: values.role as any,
                    role_detail: values.role_detail,
                    is_professional_trustee: values.is_professional_trustee,
                })
                .then((person) => {
                    onCreated?.(person);
                    enqueueSnackbar({ variant: 'success', message: 'Associated person created.' });
                });
        } else {
            updateMutation.mutateAsync(updateData).then(() => {
                enqueueSnackbar({ variant: 'success', message: 'Associated person saved.' });
                onClose?.();
            });
        }
    };

    useEffect(() => {
        if (relatedPersonIsOwnerOrg) {
            setValue('family_name', '');
            setValue('given_names', '');
            setValue('is_professional_trustee', true);
        } else {
            setValue('is_professional_trustee', false);
        }
    }, [relatedPersonIsOwnerOrg, setRelatedPersonIsOwnerOrg]);

    if (readOnly) {
        return (
            <Stack gap={4}>
                {person?.is_professional_trustee ? (
                    <ReadonlyField label="Organisation name" value={person?.given_names} />
                ) : (
                    <>
                        <ReadonlyField label="Family name" value={person?.family_name} />
                        <ReadonlyField label="Given name(s)" value={person?.given_names} />
                    </>
                )}
                <ReadonlyField
                    label="Role"
                    value={person?.role ? relatedPersonRoleLabels[person?.role] : undefined}
                />

                {person?.role === 'other' && (
                    <ReadonlyField label="Role detail" value={person.role_detail} />
                )}
            </Stack>
        );
    }

    return (
        <FormContainer formContext={form} disabled={isLoading} onSuccess={handleSubmit}>
            <Stack gap={2}>
                {!person && assumeLpr && workspace.can_add_org_as_personal_rep ? (
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={relatedPersonIsOwnerOrg}
                                onChange={(e) => setRelatedPersonIsOwnerOrg(e.target.checked)}
                            />
                        }
                        label="Add your organisation as a Personal Representative of the estate."
                    />
                ) : null}

                {(!relatedPersonIsOwnerOrg || !!person) && (
                    <TextFieldElement
                        label={relatedPersonIsOwnerOrg ? 'Organisation name' : 'Given names'}
                        name={relatedPersonIsOwnerOrg ? 'full_name' : 'given_names'}
                        fullWidth
                        required
                        InputProps={{
                            disabled: !!person || readOnly,
                        }}
                    />
                )}

                {!relatedPersonIsOwnerOrg ? (
                    <TextFieldElement
                        label="Family name"
                        name="family_name"
                        fullWidth
                        required
                        InputProps={{
                            disabled: !!person || readOnly,
                        }}
                    />
                ) : null}

                {!assumeLpr && (
                    <FormControl
                        disabled={person?.is_professional_trustee || readOnly}
                        sx={{ pb: 2 }}
                    >
                        <FormLabel>{`Is this ${
                            person?.is_professional_trustee ? 'Organisation' : 'person'
                        } a Personal Representative?`}</FormLabel>
                        <RadioGroup defaultValue={isLpr}>
                            <FormControlLabel
                                value={true}
                                onClick={() => setIsLpr(true)}
                                control={<Radio />}
                                label="Yes"
                            />
                            <FormControlLabel
                                value={false}
                                onClick={() => setIsLpr(false)}
                                control={<Radio />}
                                label="No"
                            />
                        </RadioGroup>
                    </FormControl>
                )}

                {isLpr !== undefined && (
                    <SelectElement
                        label="Role"
                        name="role"
                        required
                        disabled={readOnly}
                        options={roleOptions.map((id) => ({
                            id,
                            label: relatedPersonRoleLabels[id],
                        }))}
                    />
                )}

                {watchRole === 'other' && (
                    <TextFieldElement
                        label="Additional detail"
                        name="role_detail"
                        fullWidth
                        disabled={readOnly}
                        required
                    />
                )}

                {!readOnly && (
                    <Stack direction="row" justifyContent="flex-end">
                        <LoadingButton type="submit" variant="contained" loading={isLoading}>
                            {person ? 'Save changes' : 'Next'}
                        </LoadingButton>
                    </Stack>
                )}
            </Stack>
        </FormContainer>
    );
};
