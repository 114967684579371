import { Box, Button, Stack, Tooltip } from '@mui/material';
import { IconFlag3Filled } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import { useModal } from 'mui-modal-provider';
import { FC } from 'react';
import { queryClient } from '../../api/queryClient';
import { RelatedPersonMeta, relatedPeopleQuery, relatedPersonQuery } from '../../api/relatedPerson';
import { useWorkspace } from '../../pages/workspace/workspaceContext';
import IDVerseBadge from '../IDVerseBadge';
import QueryProgress from '../QueryProgress';
import InitiateVoIDialog from '../verificationOfIdentity/InitiateVoIDialog';
import VoIResults from '../verificationOfIdentity/VoIResults';
import VoiStatusChip from '../verificationOfIdentity/VoIStatusChip';
import { TabProps } from './RelatedPersonDrawer';

interface RelatedPersonVoIProps extends Omit<TabProps, 'person'> {
    person: RelatedPersonMeta;
}

const RelatedPersonVoI: FC<RelatedPersonVoIProps> = ({ person, readOnly }) => {
    const workspace = useWorkspace();
    const { showModal } = useModal();
    const query = useQuery(relatedPersonQuery(person.id));
    const personDetail = query.data;

    const canInitiateVoI = personDetail?.e_voi === null && !readOnly;
    const canRetriggerVoI =
        ['completed', 'expired', 'cancelled'].includes(personDetail?.e_voi_status || '') &&
        !readOnly;

    if (person.is_professional_trustee) {
        return 'NOT APPLICABLE';
    }

    const onInitiateVoI = () => {
        queryClient.invalidateQueries(relatedPersonQuery(person.id));
        queryClient.invalidateQueries(relatedPeopleQuery(workspace.id));
    };

    return (
        <Stack gap={4}>
            <QueryProgress query={query}>
                <Stack gap={2}>
                    {!person.e_voi ? (
                        <Stack direction="row" gap={1} alignItems="center">
                            <VoiStatusChip
                                status={personDetail?.e_voi_status || undefined}
                                requested={personDetail?.voi_requested || undefined}
                                sx={{ width: 'fit-content' }}
                            />
                            {person?.is_flagged &&
                                (person.e_voi_status === null ||
                                    person.e_voi_status === 'cancelled' ||
                                    person.e_voi_status === 'expired') && (
                                    <Tooltip title="Verification of Identity was requested by a Participant. Please initiate Electronic Verification of Identity.">
                                        <Box
                                            component={IconFlag3Filled}
                                            size={24}
                                            sx={{ ml: 1 }}
                                            color="warning.dark"
                                        />
                                    </Tooltip>
                                )}
                        </Stack>
                    ) : (
                        <VoIResults
                            eVoI={person.e_voi}
                            retrigger={
                                canRetriggerVoI
                                    ? () =>
                                          showModal(InitiateVoIDialog, {
                                              relatedPersonId: person.id,
                                              onSubmit: onInitiateVoI,
                                          })
                                    : undefined
                            }
                        />
                    )}

                    {canInitiateVoI && (
                        <Button
                            onClick={() =>
                                showModal(InitiateVoIDialog, {
                                    relatedPersonId: person.id,
                                    onSubmit: onInitiateVoI,
                                })
                            }
                            variant="outlined"
                            color="inherit"
                            sx={{ width: 'fit-content' }}
                        >
                            Initiate verification
                        </Button>
                    )}
                </Stack>
            </QueryProgress>
            <IDVerseBadge alignSelf="flex-end" />
        </Stack>
    );
};

export default RelatedPersonVoI;
