import { zodResolver } from '@hookform/resolvers/zod';
import {
    Box,
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogTitle,
    Drawer,
    DrawerProps,
    FormControlLabel,
    FormLabel,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import { IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui';
import z from 'zod';
import { RequestMeta, updateRequestMutation } from '../../../api/requests';
import ReadonlyField from '../../../components/fields/ReadonlyField';
import useUser from '../../../hooks/useUser';
import { characterLimit, noSpecialChars, requiredString } from '../../../validationRules';
import RequestStatus from './RequestStatus';

const validationSchema = z.object({
    response: requiredString.and(characterLimit(300)).and(noSpecialChars),
});

interface RequestDrawerProps extends DrawerProps {
    onClose?: () => void;
    request: RequestMeta;
    cachedResponse?: string;
    onChangeResponse?: (response: string) => void;
}

export default function RequestDrawer({
    onClose,
    request,
    cachedResponse,
    onChangeResponse,
    ...drawerProps
}: RequestDrawerProps) {
    const user = useUser();
    const organisationId = user?.de_rep_organisation?.id ?? user?.institution_organisation?.id;
    const isRequestCreatedByUser = request.sender === organisationId;
    const canRespond =
        request.recipient === organisationId && request.status === 'awaiting_response';

    const canReview =
        request.sender === organisationId &&
        (request.status === 'response_provided' || request.status === 'completed');
    const [isReviewed, setIsReviewed] = useState(request.status === 'completed');
    const isReviewToggled = isReviewed
        ? request.status === 'response_provided'
        : request.status === 'completed';

    const updateRequest = useMutation(updateRequestMutation(request.id));

    const handleSuccess = (values: any) => {
        updateRequest
            .mutateAsync({
                response: values.response,
            })
            .then(() => onClose?.());
    };

    const handleToggleResolved = () => {
        updateRequest
            .mutateAsync({
                is_completed: isReviewed,
            })
            .then(() => onClose?.());
    };

    const formMethods = useForm({
        mode: 'onChange',
        defaultValues: {
            response: request.response || '',
        },
        resolver: zodResolver(validationSchema),
    });

    return (
        <Drawer anchor="right" SlideProps={{ appear: true }} onClose={onClose} {...drawerProps}>
            <Stack sx={{ flexGrow: 1, width: '40rem' }}>
                <DialogTitle component={Stack} direction="row" gap={2} alignItems="center">
                    <IconButton edge="start" aria-label="close" onClick={() => onClose?.()}>
                        <IconX />
                    </IconButton>
                    {isRequestCreatedByUser ? 'Sent Request' : 'Received Request'}
                </DialogTitle>
                <FormContainer formContext={formMethods} onSuccess={handleSuccess}>
                    <DialogContent>
                        <Stack gap={4}>
                            <ReadonlyField
                                label="Requestor"
                                value={request.sender_organisation_name}
                            />

                            <ReadonlyField label="Theme" value={request.theme_label} />

                            <ReadonlyField label="Issue" value={request.issue} />

                            <ReadonlyField label="Comments" value={request.comment} />

                            <Stack gap={0.2}>
                                <FormLabel>Response</FormLabel>
                                {request.response || canRespond ? (
                                    <TextFieldElement
                                        multiline
                                        name="response"
                                        fullWidth
                                        required
                                        minRows={5}
                                        maxRows={10}
                                        disabled={!canRespond}
                                        placeholder="Confirm the action you have taken to resolve the Request."
                                        onChange={(e) => onChangeResponse?.(e.target.value)}
                                        sx={{ mt: 1 }}
                                        InputProps={{
                                            sx: {
                                                pt: 1.5,
                                            },
                                        }}
                                    />
                                ) : (
                                    <Typography variant="body2">
                                        <RequestStatus request={request} />
                                    </Typography>
                                )}
                            </Stack>
                            {canReview && (
                                <Stack direction="row">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onClick={() => setIsReviewed(!isReviewed)}
                                                checked={isReviewed}
                                            />
                                        }
                                        label="Confirm Request has been reviewed"
                                    />

                                    <Box flexGrow={1} />

                                    {isReviewToggled && (
                                        <Button
                                            variant="contained"
                                            color="success"
                                            onClick={handleToggleResolved}
                                        >
                                            Save
                                        </Button>
                                    )}
                                </Stack>
                            )}
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        {canRespond && (
                            <Button variant="contained" color="success" type="submit">
                                Send response
                            </Button>
                        )}
                    </DialogActions>
                </FormContainer>
            </Stack>
        </Drawer>
    );
}
