import {
    Box,
    Paper,
    PaperProps,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
} from '@mui/material';
import { IconCaretDownFilled, IconCaretUpDown, IconCaretUpFilled } from '@tabler/icons-react';
import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { DocumentUnion } from '../../api/document';
import { components } from '../../api/schema';
import {
    CellComponentProps,
    DocumentTableRowCreatedAt,
    DocumentTableRowFile,
    DocumentTableRowStatus,
    DocumentTableRowUploadedByUser,
} from './DocumentsTableCells';
import DocumentTableRow from './DocumentsTableRow';

export interface BaseDocument {
    id: string;
    workspace: string;
    membership: string | null;
    file?: string;
    file_name?: string | null;
    task_type?: components['schemas']['TaskTypeEnum'] | components['schemas']['NullEnum'] | null;
    created_at: string;
    uploaded_by: components['schemas']['CreatedByUser'];
    document_type?: string;
    is_infected: boolean | null;
}

export interface Column {
    id: string;
    label?: string;
    sortable?: string;
    Component: React.ComponentType<CellComponentProps>;
}

interface DocumentsTableProps extends PaperProps {
    documents?: DocumentUnion[];
    /**
     * Defaults to file, createdAt and uploadedBy.
     */
    columns?: Column[];
    /**
     * In addition to the default columns.
     */
    extraColumns?: Column[];
    sort?: string;
    defaultSort?: string;
    placeholder?: React.ReactNode;
    onDelete?: (id: string) => void;
}

function DocumentsTable({
    documents,
    columns: defaultColumns,
    extraColumns,
    sort,
    defaultSort,
    placeholder,
    onDelete,
    ...paperProps
}: DocumentsTableProps) {
    const [searchParams] = useSearchParams();

    const sortColumn = sort?.replace('-', '');
    const sortDirection = !sort?.startsWith('-') ? 'asc' : 'desc';

    function sortLink(column: string) {
        const params = new URLSearchParams(searchParams);
        const sort = (sortColumn === column && sortDirection === 'asc' ? '-' : '') + column;
        if (sort === defaultSort) params.delete('sort');
        else params.set('sort', sort);
        return `?${params}`;
    }

    const isSortActive = (column: Column) => sortColumn === column.sortable;

    const columns: Column[] = defaultColumns
        ? defaultColumns
        : [
              {
                  id: 'file',
                  label: 'File',
                  Component: DocumentTableRowFile,
              },
              {
                  id: 'createdAt',
                  label: 'Uploaded',
                  Component: DocumentTableRowCreatedAt,
              },
              {
                  id: 'uploadedBy',
                  label: 'Uploaded by',
                  Component: DocumentTableRowUploadedByUser,
              },
          ];

    columns.push({
        id: 'status',
        label: '',
        Component: DocumentTableRowStatus,
    });

    return (
        <TableContainer
            component={Paper}
            {...paperProps}
            sx={{ display: 'flex', overflow: 'hidden', borderRadius: 0, ...paperProps.sx }}
        >
            {documents?.length ? (
                <Table
                    stickyHeader
                    sx={{
                        whiteSpace: 'nowrap',
                        '& .MuiTableCell-stickyHeader': {
                            bgcolor: 'background.paper',
                        },
                        '& tr > *:first-child': { pl: 2 },
                        '& tr > *:last-child': { pr: 2 },
                    }}
                >
                    <TableHead>
                        <TableRow>
                            {columns.map((column, index) => (
                                <TableCell key={index}>
                                    {column.sortable ? (
                                        <TableSortLabel
                                            component={Link}
                                            state={{ keepPreviousData: 'true' }}
                                            to={sortLink(column.sortable)}
                                            active={isSortActive(column)}
                                            direction={sortDirection}
                                            IconComponent={() => (
                                                <Box
                                                    component={
                                                        !isSortActive(column)
                                                            ? IconCaretUpDown
                                                            : sortDirection === 'asc'
                                                              ? IconCaretUpFilled
                                                              : IconCaretDownFilled
                                                    }
                                                    sx={{
                                                        opacity: isSortActive(column) ? 0.8 : 0.5,
                                                        ml: 0.25,
                                                        strokeWidth: '1.25px',
                                                    }}
                                                    size={22}
                                                />
                                            )}
                                            hideSortIcon={false}
                                        >
                                            {column.label}
                                        </TableSortLabel>
                                    ) : (
                                        column.label
                                    )}
                                </TableCell>
                            ))}
                            <TableCell sx={{ width: 0 }} />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {documents.map((document, index) => (
                            <DocumentTableRow
                                key={index}
                                columns={columns}
                                document={document}
                                onDelete={onDelete}
                            />
                        ))}
                    </TableBody>
                </Table>
            ) : (
                placeholder
            )}
        </TableContainer>
    );
}

export default DocumentsTable;
