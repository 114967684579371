import { User } from './api/user';

export const nullableBooleanToYesNo = (value: boolean | null | undefined): string | undefined => {
    if (value === null || value === undefined) {
        return undefined;
    }

    return value ? 'yes' : 'no';
};

export function isWorkspaceOwner(user?: User | null): boolean {
    return user?.de_rep_organisation !== null;
}

// Prevents the click event from propagating to the parent element
export const preventClickthrough = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
};
