import { FlagRounded } from '@mui/icons-material';
import { Box, Button, Container, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { IconArrowLeft, IconShare2 } from '@tabler/icons-react';
import { useModal } from 'mui-modal-provider';
import { useRef } from 'react';
import { Outlet, Link as RouterLink } from 'react-router-dom';
import AcceptInvitationHeader from '../../components/AcceptInvitationHeader';
import CloseWorkspaceDialog from '../../components/CloseWorkspaceDialog';
import CopyrightFooter from '../../components/CopyrightFooter';
import ExportDocumentsDialog from '../../components/ExportDocumentsDialog';
import useScrollToTop from '../../hooks/useScrollToTop';
import useUser from '../../hooks/useUser';
import { isWorkspaceOwner } from '../../utils';
import Sidebar from './WorkspaceSidebar';
import { useWorkspace } from './workspaceContext';
import { useWorkspaceMembership } from './workspaceMembershipContext';

function WorkspaceLayout() {
    const user = useUser();
    const workspace = useWorkspace();
    const membership = useWorkspaceMembership();

    const scrollBoxRef = useRef<HTMLDivElement>(null);
    useScrollToTop(scrollBoxRef);

    const { showModal } = useModal();

    return (
        <Stack
            ref={scrollBoxRef}
            sx={{
                flexGrow: 1,
                overflowY: 'scroll',
                backgroundColor: 'background.default',
                height: '100%',
            }}
        >
            <Container
                maxWidth="xl"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                }}
            >
                <Stack
                    direction="row"
                    component="header"
                    gap={1}
                    sx={{
                        alignItems: 'center',
                        zIndex: 'appBar',
                        backgroundColor: 'background.default',
                        whiteSpace: 'nowrap',
                        position: 'sticky',
                        height: '80px',
                        top: 0,
                        pb: 1,
                        pl: 2,
                    }}
                >
                    <Stack direction="row" gap={1} alignItems="center">
                        <Tooltip title="Back to Workspaces" placement="right">
                            <IconButton component={RouterLink} to=".." edge="start">
                                <IconArrowLeft />
                            </IconButton>
                        </Tooltip>
                        <Stack direction="row" alignItems="baseline" gap={2}>
                            <Typography variant="h3">
                                {workspace?.deceased_detail.full_name}
                            </Typography>
                            <Stack direction="row" gap={0.5} alignItems="center">
                                <Typography variant="body2" color="text.secondary" fontWeight={400}>
                                    eXc ID:
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {workspace?.external_id}
                                </Typography>
                            </Stack>
                            <Stack direction="row" gap={0.5} alignItems="center">
                                <Typography variant="body2" color="text.secondary" fontWeight={400}>
                                    Your ref:
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {isWorkspaceOwner(user)
                                        ? workspace?.case_reference
                                        : membership?.participant_reference}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Box flexGrow={1} />
                    <Button
                        color="inherit"
                        sx={{ alignSelf: 'center', mt: 1, opacity: 0.8 }}
                        startIcon={<IconShare2 />}
                        onClick={() => showModal(ExportDocumentsDialog)}
                    >
                        Export documents
                    </Button>
                    <Button
                        color="warning"
                        sx={{ alignSelf: 'center', mt: 1 }}
                        startIcon={<FlagRounded />}
                        onClick={() => showModal(CloseWorkspaceDialog)}
                    >
                        Finalise Workspace
                    </Button>
                </Stack>
                <AcceptInvitationHeader />
                <Stack
                    direction="row"
                    gap={3}
                    sx={{
                        flexGrow: 1,
                    }}
                >
                    <Sidebar />
                    <Stack
                        sx={{
                            minWidth: 0,
                            flexGrow: 1,
                        }}
                    >
                        <Outlet />
                        <CopyrightFooter />
                    </Stack>
                </Stack>
            </Container>
        </Stack>
    );
}

export default WorkspaceLayout;
